import './styles/main.scss';
import ReactDOM from 'react-dom';
import { StrictMode } from 'react';
import { App } from './app';

ReactDOM.render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById('root')
);
