import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { FC, lazy, LazyExoticComponent, Suspense } from 'react';
import { ErrorCode } from './constants/error-code';
import { AppPaths } from './constants/paths';
import { Loader } from './components/common/loader';

const AttachmentPage: LazyExoticComponent<FC> = lazy(() => import('./pages/attachment-resolver'));
const ArticlePage: LazyExoticComponent<FC> = lazy(() => import('./pages/article-resolver'));
const ErrorPage: LazyExoticComponent<FC<{code: ErrorCode}>> = lazy(() => import('./pages/error'));

export const Pages = () => {
    return (
        <Suspense fallback={<Loader/>}>
            <Router basename='/'>
                <Switch>
                    <Route
                        exact={true}
                        path={AppPaths.ATTACHMENT_DOWNLOAD}
                        component={AttachmentPage}
                    />
                    <Route
                        exact={true}
                        path={AppPaths.ARTICLE_VIEW}
                        component={ArticlePage}
                    />
                    <Route path='*' render={() => <ErrorPage code={ErrorCode.PAGE_NOT_FOUND}/>} />
                </Switch>
            </Router>
        </Suspense>
    );
};
